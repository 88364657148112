import React, { useState } from 'react'
import './settings.scss'
import Dashboard from '../DashBoard'
import PersonalSetting from './PersonalSetting/PersonalSetting'
import OtherSettings from './OtherSettings/OtherSettings'
import Auth from '../../Classes/Auth'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import Tabs from '../../CommanComponents/Tabs/Tabs'
import MySubscription from './MySubscription/MySubscription'
import { adminID } from '../../config'
const Settings = () => {
    const [activeTab, setActiveTab] = useState(Auth?.boolUserType() !== 5 ? 'Personal' : "Setting")
    return (
        <>
            <div className="main-setting-wrappper">
                <SectionHeading ClassName={'mb-20'}>
                    Settings
                </SectionHeading>
                {/* <div className="tabs-wrapper d-flex align-center">
                    <button className="tab-btn transparent text-xxs w-700 active">Personal</button>
                    <button className="tab-btn transparent text-xxs w-700">Setting</button>
                </div> */}
                <div className="tabs-main-wrapper">
                    <Tabs
                        tabs={[Auth?.boolUserType() !== 5 && 'Personal', 'Setting', Auth?.boolUserType() !== 5 && adminID !== Auth?.user().user_id && "My Subscription"].filter(Boolean)}
                        defaultValue={activeTab}
                        activeTabHandle={(e) => setActiveTab(e)}
                        TabButtonClass={"default-tab text-xs w-700"}
                        ActiveTabClass={"active"}
                        TabWrapperClass={"setting-tabs-wrapper"}
                    />
                </div>
                <div className="setting-content-wrap">
                    {activeTab === "Personal" ? <PersonalSetting /> :
                        activeTab === "Setting" ? <OtherSettings /> :
                            <MySubscription />

                    }
                </div>
            </div>

        </>
    )
}

export default Settings