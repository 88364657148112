import React, { useEffect, useRef, useState } from 'react'
import './allEmployees.scss'
import data from './data.json'
import SearchControl from '../../CommanComponents/SearchControl/SearchControl'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import TopActionButtons from '../DashBoard/RightBarPannel/TopActionButtons/TopActionButtons'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import ProgressBarCard from './Cards/RectangleCards/ProgressBarCard'
import NotificationCard from './Cards/NotificationCards/NotificationCard'
// import './cardStyle.scss'
import Cards from './Cards/RectangleCards/RecCards'
import useAllEmployees from '../../CustomHooks/AllEmployees/useAllEmployees'
import Auth from '../../Classes/Auth'


const AllEmployees = () => {
    const { allEmployees } = useAllEmployees()
    const IndividualDropRef = useRef(null)

    const [searchVal, setSearchVal] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [productCount, setProductCount] = useState(0);
    const [pageInput, setPageInput] = useState(15);
    const [individualActionDrop, setIndividualActionDrop] = useDetectOutsideClick(IndividualDropRef, false)
    const handleSearch = (e) => {
        setSearchVal(e.target.value)
    }
    const handleResetSearch = () => {
        setSearchVal('')
    }
    const handlePagination = (page) => {
        setCurrentPage(page)
    }
    useEffect(() => {
        setProductCount(data?.length)
    }, [])

    const handleManageBtn = (val) => {
        setIndividualActionDrop(individualActionDrop === val ? -1 : val)
    }

    return (
        <>
            <div className="all-employees-wrapper" style={{ overflowX: 'auto' }}> {/* Apply overflow-x: auto; */}
                <div className="all-employees-wrapper">
                    <div className="employees-wrapper">
                        <div className="heading-search-item justify-between align-center">
                            <SectionHeading>
                                Dashboard
                            </SectionHeading>
                            {/* <div className='d-flex align-center justify-center search-page-wrap '>
                                <SearchControl
                                    value={searchVal}
                                    placeholder={'Search...'}
                                    onChange={handleSearch}
                                    reset={() => handleResetSearch()}
                                    classNameWrappper={'employees-search'}
                                />
                           
                            </div> */}
                            {/* <TopActionButtons showEmployee={true} showUploadDoc={true} /> */}

                        </div>

                        <div class={`cards-main-wrapper ${(Auth.boolUserType() == 2 || Auth?.boolUserType() == 4) && 'typing-center-wrapper'}`}>
                            <Cards heading={'Expiring in 7 Days'} val={allEmployees?.data?.total_documents_in_7days} className={'active-box'} />
                            <Cards heading={'Expiring in 30 Days'} val={allEmployees?.data?.total_documents_in_30days} />
                            <Cards heading={'Expired'} val={allEmployees?.data?.total_documents_expired} />
                            <Cards heading={'Total Document'} val={allEmployees?.data?.total_documents} />
                            <div className="dash-notification-wrapper">
                                <NotificationCard data={allEmployees?.data?.notifications} />
                            </div>

                            <div className='middle-item-wrap'>
                                {Auth?.boolUserType() !== 3 && <Cards heading={`${(Auth.boolUserType() == 1 || Auth?.boolUserType() == 3) ? 'Total' : ''} Employee`} val={allEmployees?.data?.total_employees} />}

                                {(Auth.boolUserType() == 2 || Auth?.boolUserType() == 4) &&
                                    <Cards heading={`Clients`} className={'clients-card'} val={allEmployees?.data?.total_clients} />}

                                <Cards heading={'Reminder'} val={allEmployees?.data?.total_reminders ? allEmployees?.data?.total_reminders : 0} />

                            </div>


                            <Cards heading={'Support Ticket'} val={allEmployees?.data?.support_ticket_count} />
                            {/* <Cards /> */}

                            {/* <div className="progress-wrap"> */}
                            {/* <ProgressBarCard progress="60" className={"progress-wrap"} /> */}
                            {/* </div> */}

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AllEmployees