import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import './topRibbon.scss';
import logo from '../../../Assets/icons/Logo.svg'
import SearchControl from '../../../CommanComponents/SearchControl/SearchControl';
import useDetectOutsideClick from '../../../CustomHooks/useDetectOutsideClick';
import useWindowDimensions from '../../../CustomHooks/useWindowDimensions';
import NotificationShow from '../../../CommanComponents/NotificationShow/NotificationShow';
import { ActiveTabContext } from '..';
import Auth from '../../../Classes/Auth';
import { Link, useNavigate } from 'react-router-dom';
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton';
import { useDispatch, useSelector } from 'react-redux';
import { showSuccessToast } from '../../../store/Actions/SuccessMessagePopup';
import { dateFormat } from '../../../CustomHooks/dateFormat';
import { notificationTrigger, setNotifications, setSeenNoty } from '../../../store/Actions/Notifications';
import { adminID, noticationTiggerTime } from '../../../config';
import { useLocation } from 'react-router-dom';
import Storage from '../../../Classes/Storage';
import { noty_count } from '../../../Constant/auth';
import NotificationsPop from './Notifications/NotificationsPop';
const TopRibbon = () => {
    const employeeManagement = useSelector((state) => state.employeeManagement)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const notificationPopRef = useRef(null)
    const openProfilePopRef = useRef(null)
    const searchRef = useRef(null)
    const [searchValue, setSearchValue] = useState('')
    const [searchBtn, setSearchBtn] = useDetectOutsideClick(searchRef, false)
    const [openNotification, setOpenNotification] = useDetectOutsideClick(notificationPopRef, false)
    const [openProfilePop, setOpenProfilePop] = useDetectOutsideClick(openProfilePopRef, false)
    const { setToggelBtn, setBurgerBtn } = useContext(ActiveTabContext)
    const notifications = useSelector((state) => state.notifications)
    const userId = Auth?.user()?.user_id
    const localNoty = Storage.getBool(noty_count)
    const [seenCount, setSeenCount] = useState(Storage.alive(noty_count) ? localNoty : 0);

    const { pathname } = useLocation()
    const user = Auth.user()
    const searchHandle = (e) => {
        setSearchValue(e.target.value)
    }

    const handleResetSearch = () => {
        setSearchBtn(e => !e)
        setSearchValue('')
    }

    const notifictionBtnHandle = () => {
        setOpenNotification(e => !e)
    }

    // this function make for side toggling
    const toggleHandle = () => {
        setToggelBtn(e => !e)
    }
    // get window width
    const { width } = useWindowDimensions();


    // logout Handle
    const logoutHandle = () => {
        Auth.logout()
        // navigate('/login')
        window.location.href = "/login"
        dispatch(showSuccessToast("Logout successfully!"))
    }
    // useLayoutEffect(() => {
    //     if (openNotification) {
    //         dispatch(notificationTrigger(userId))
    //         dispatch(setNotifications(userId))

    //     }
    // }, [openNotification])


    // function onMsgClickHandler(id, is_seen) {
    //     if (!is_seen) {
    //         const formData = new FormData();
    //         formData.append('id', id);
    //         formData.append('is_seen', 1);
    //         dispatch(setSeenNoty(userId, formData))
    //     }
    // }
    // Effect to update the count when reminders change
    useEffect(() => {
        let count = 0;
        if (notifications?.data?.length) {
            notifications?.data?.forEach(val => {
                if (val.is_seen === false) {
                    count++;
                    // console.log(val.is_seen, "dsfsfg")
                }
            });
            // Update the state with the count
            Storage.setBool(noty_count, count)
            setSeenCount(count);
        } else {
            setSeenCount(count);
        }

    }, [notifications?.data, noty_count]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(notificationTrigger(userId))
            dispatch(setNotifications(userId))
        }, noticationTiggerTime);

        return () => clearInterval(interval);
    }, []);

    console.log(localNoty != 0, "ADsfdc")
    return (
        <div className="topribbon-main-wrapper">
            <div className={`top-ribbon-wrap 
                ${Auth.userType() === "Business Owner" ? "business-owner" :
                    Auth.userType() === "Typing Center" || Auth.userType() === "Typing Center User" ? "typing-center"
                        :
                        Auth.userType() === "Individual" ? "individual" : Auth.userType() === "employee_user" ? "employee_user" : ''
                }
            `}>
                <div className="top-ribbon-item1 ">
                    <div className='hamburger-wrapper d-flex align-center'>
                        <button className='hamburger-btn transparent mr-20 d-flex justify-center' onClick={() => width >= 993 ? toggleHandle() : setBurgerBtn(e => !e)}>
                            <i className='hamburger-icon'></i>
                        </button  >
                        <img src={width >= 350 ? logo : logo} alt="SnapIT" className='logo-item' />
                    </div>
                </div>
                <div className="top-ribbon-item2 ">
                    <p className="name-heading primary text-sm w-500 text-center">
                        {
                            Auth?.boolUserType() !== 4 ?
                                user?.business_name
                                : Auth?.boolUserType() === 4 ? user?.full_name : ''

                        }

                        {Auth?.boolUserType() == 2 && employeeManagement?.client?.business_name !== "Select client*" && pathname.includes('/employees-management') ? ' - ' + employeeManagement?.client?.business_name : ''}

                        {Auth?.boolUserType() == 3 && adminID !== Auth?.user().user_id && user?.first_name + " " + user?.last_name}
                        {Auth?.boolUserType() == 3 && adminID === Auth?.user().user_id && "Snapit Admin"}
                        {Auth?.boolUserType() == 5 && user?.full_name}
                    </p>
                </div>
                <div className="top-ribbon-item3">
                    {/* <div className="search-wrap" ref={searchRef}>
                        <div name="search" className={`gloabal-search-wrapper relative ${searchBtn ? "active" : ""} `}>
                            <input type="text" className={`input-search `} placeholder="Type to Search..." value={searchValue} onChange={searchHandle} />
                            <button className="btn-search" onClick={() => handleResetSearch()}>
                                {<i className={`search-icon  ${searchBtn ? "close-icon" : ""}`} > </i>}
                            </button>

                        </div>
                    </div> */}
                    {Auth?.boolUserType() !== 5 && adminID !== Auth?.user().user_id && <div className="bell-notification-wrapper relative" ref={notificationPopRef}>
                        <button className="header-action-btn relative transparent" onClick={notifictionBtnHandle}>
                            <i className="bell-icon icons"></i>
                            {localNoty != 0 && localNoty != null ?
                                <div className="notification-count">
                                    <NotificationShow value={localNoty} />
                                </div> : ""
                            }
                        </button>
                        {/* {openNotification &&
                            <div className="notification-popup-wrapper absolute d-flex align-center justify-center" >
                                <div className="notification-popup-wrap ">
                                
                                    <div className="invitaion-item-wrap">
                                        <p className="text-xs secondary w-600 mb-5 heading">Notifications</p>
                                        {notifications?.data?.length ? notifications?.data?.map((val, key) => {
                                            return (
                                                <div className="invitation-item d-flex pointer" key={key} onClick={() => onMsgClickHandler(val?.id, val?.is_seen)}>
                                                    <div className="icon-wrap d-flex align-center justify-center">
                                                        <i className="profile-icon icons"></i>
                                                    </div>
                                                    <div className="message-wrap">
                                                        <p className={`w-400 text-2xs text-left base ${!val?.is_seen && "w-600"}`}>

                                                            Hi {val?.client_name} , Your Employee  {val?.first_name + " " + val?.last_name} document  {val?.document_type} is about to expire on {dateFormat(val.date_of_expiry)}.
                                                                                                                </p>

                                                    </div>
                                                </div>
                                            )
                                        }) :
                                            <>
                                                <div className="invitation-item not-found-wrap "  >
                                                    <div className="message-wrap">
                                                        <p className="w-400 text-2xs text-center base">
                                                            No record found!
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="show-all-wrap">
                                        <Link to={'/notifications'} className="text-2xs w-500 primary text-center" onClick={() => setOpenNotification(e => !e)}>Show all</Link>
                                    </div>
                                </div>
                            </div>
                        } */}

                        {openNotification && <NotificationsPop setOpenNotification={setOpenNotification} />}
                    </div>}
                    <div className="bell-notification-wrapper relative" ref={openProfilePopRef}>
                        <button className="header-action-btn profile-wrap " onClick={() => setOpenProfilePop(e => !e)}>
                            <i className="profile-icon icons"></i>
                        </button>
                        {openProfilePop &&
                            <div className="notification-popup-wrapper add-profile-popup absolute">
                                <div className="notification-popup-wrap">
                                    <div className="profile-details-wrap mb-15">
                                        <p className="text-xxs secondary w-600 mb-10">Account</p>
                                        <div className="profile-item">
                                            <div className="profile-pic-wrap d-flex align-center justify-center pointer">
                                                {false ?
                                                    <img src="" alt="" />
                                                    : <p className="text-ss w-600 white " >
                                                        {
                                                            (Auth?.boolUserType() == 1 || Auth?.boolUserType() == 2) ?
                                                                user?.business_name?.match(/\b\w/g)?.slice(0, 2).join('').toUpperCase()
                                                                : Auth?.boolUserType() == 4 || Auth?.boolUserType() == 5 ?
                                                                    user?.full_name?.match(/\b\w/g)?.slice(0, 2).join('').toUpperCase()
                                                                    : Auth?.boolUserType() == 3 ?
                                                                        user?.first_name?.match(/\b\w/g)?.slice(0, 2).join('').toUpperCase() + user?.last_name?.match(/\b\w/g)?.slice(0, 2).join('').toUpperCase() : ''
                                                        }

                                                    </p>
                                                }
                                            </div>
                                            <div className="user-name-wrap">
                                                <p className="w-500 text-xxs secondary mb-3">
                                                    {
                                                        Auth?.boolUserType() == 1 || Auth?.boolUserType() == 2 ?
                                                            user?.business_name
                                                            : Auth?.boolUserType() == 3 ? user?.first_name + " " + user?.last_name
                                                                : Auth?.boolUserType() === 4 || Auth?.boolUserType() == 5 ? user?.full_name : ''
                                                    }
                                                </p>
                                                <p className="w-300 text-xxs secondary">
                                                    {user?.email}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="other-options">
                                        <p className="text-xxs secondary w-600 mb-15">Account Settings</p>
                                        <Link className="other-item d-flex align-center mb-5" to={'/settings'} onClick={() => setOpenProfilePop(e => !e)}>
                                            <i className="other-icons setting"></i>
                                            <p className="w-400 text-xxs secondary">Settings</p>
                                        </Link>
                                        <Link className="other-item d-flex align-center mb-5" to={'/faq'} onClick={() => setOpenProfilePop(e => !e)}>
                                            <i className="other-icons help"></i>
                                            <p className="w-400 text-xxs secondary">FAQ</p>
                                        </Link>
                                        <Link className="other-item d-flex align-center mb-5" to={'/support'} onClick={() => setOpenProfilePop(e => !e)}>
                                            <i className="other-icons support"></i>
                                            <p className="w-400 text-xxs secondary">Support</p>
                                        </Link>
                                        <div className="other-item d-flex align-center" onClick={logoutHandle} >
                                            <i className="other-icons logout"></i>
                                            <p className="w-400 text-xxs secondary">Logout</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default TopRibbon