import React from 'react'
import useClientPersonalData from '../../../CustomHooks/Clients/useClientPersonalData'
import dummyUserImg from '../../../Assets/images/black-user-img.png'
import ImageViewer from '../../../CommanComponents/ImageViewer/ImageViewer'
import { dateFormat } from '../../../CustomHooks/dateFormat'
import FormError from '../../../CommanComponents/Form/FormError'
import PhoneInput from 'react-phone-input-2'
import CountrySelector from '../../../CommanComponents/CSC2/CountrySelector'
import StateSelector from '../../../CommanComponents/CSC2/StateSelector'
import CitySelector from '../../../CommanComponents/CSC2/CitySelector'
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton'

const PersonalClientData = ({ activeEdit, setActiveEdit }) => {
    const { type, clients, imageRef, inputFile, onProflieChange, singleClient,
        clientData, handleInput, handleRegexValidation,
        showCPError, regexError, todayDate, handlePhoneInput, handlePersonalClientSaveBtn,

    } = useClientPersonalData(setActiveEdit)
    console.log(regexError === 'email', "clientData?.email?.value.length 1")
    return (
        <div className="personal-details-main-wrapper">
            <div className="employee-basic-details-wrapper d-flex justify-between ">
                {/* <div className="employee-img-wrap">
                    <ImageViewer object={singleClient?.data?.employee_image} defaultImage={dummyUserImg} ref={imageRef} alt="Profile-Pic" className='user-img' />
                    <div className="pencile-wrap d-flex justify-center align-center" onClick={() => inputFile.current.click()}>
                        <i className='pencile-icon'></i>
                        <input id="myInput"
                            type="file"
                            ref={inputFile}
                            style={{ display: 'none' }}
                            onChange={(e) => onProflieChange(e, imageRef)}
                            accept='image/*'
                        />
                    </div>
                </div> */}
                <div className="employee-basic-details">
                    <div className="basic-details-wrap">
                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Business Name
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {clients.client_type === 'new' || type === 'new' ? "-" : singleClient?.data?.business_name}
                            </p>
                        </div>
                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                E-mail
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {clients.client_type === 'new' || type === 'new' ? "-" : singleClient?.data?.email}
                            </p>
                        </div>
                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Phone no
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {clients.client_type === 'new' || type === 'new' ? "-" : singleClient?.data?.phone_no}
                            </p>
                        </div>
                        {/* <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Date of birth
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {clients.client_type === 'new' || type === 'new' ? "-" : dateFormat(singleClient?.data?.date_of_birth)}
                            </p>
                        </div> */}

                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Business license no
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {clients.client_type === 'new' || type === 'new' ? "-" : singleClient?.data?.business_licence_no}
                            </p>
                        </div>

                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Emirates id
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {clients.client_type === 'new' || type === 'new' ? "-" : singleClient?.data?.Emirates_ID}
                            </p>
                        </div>


                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Nationality
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {clients.client_type === 'new' || type === 'new' ? "-" : singleClient?.data?.Nationality}
                            </p>
                        </div>
                        <div className="basic-details-item">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Text id
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {clients.client_type === 'new' || type === 'new' ? "-" : singleClient?.data?.text_id}
                            </p>
                        </div>
                        <div className="basic-details-item ">
                            <p className="details-title secondary text-xxs w-600 mb-3">
                                Address
                            </p>
                            <p className="details-desc secondary text-xxs w-400">
                                {clients.client_type === 'new' || type === 'new' ? "-" : `${singleClient?.data?.address},${singleClient?.data?.city},${singleClient?.data?.state},${singleClient?.data?.country},${singleClient?.data?.postal_code}`}
                            </p>
                        </div>
                        <div className='basic-details-item extra-div'></div>

                    </div>
                </div>
            </div>
            {
                activeEdit &&
                <div className="employee-details-edit-wrapper mt-15">
                    <div className="form-wrapper">
                        <div className="form"  >
                            <div className="form-control">
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Business name* </label>
                                    <input type="text"
                                        name='business_name'
                                        value={clientData?.business_name?.value}
                                        onChange={handleInput}
                                    // onKeyUp={handleInput}
                                    // onKeyDown={(e) => handleRegexValidation(e)}
                                    />
                                    <FormError
                                        show={!clientData?.business_name?.isValid && showCPError || regexError === 'business_name'}
                                        error={regexError === 'business_name' ? 'Please enter only alphabetical character .' : 'Please enter Business Name.'} />

                                    {/* {regexError === 'business_name' && <FormError show={true} error={'Please enter only alphabetical character .'} />} */}
                                </div>
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs'  > E-mail* </label>
                                    <input type="text"
                                        name='email'
                                        value={clientData?.email?.value}
                                        onChange={handleInput}
                                        onKeyUp={handleInput}
                                    />
                                    <FormError show={(!clientData?.email?.isValid && showCPError) || clients?.error || regexError === 'email'}
                                        error={
                                            regexError === 'email' ? "Please enter email id." : (!clientData?.email?.isValid && showCPError) ? 'Please enter valid email id.' :
                                                clients?.error ? clients?.message :
                                                    ''} />

                                </div>
                                <div className="form-field ">
                                    <div className="mobile-no-wrap">
                                        <label htmlFor="" className='mb-3 d-block text-xxs'  > Phone no* </label>

                                        <PhoneInput
                                            countryCodeEditable={false}
                                            containerClass="custom-form-container"
                                            inputClass="custom-form-input"
                                            specialLabel
                                            value={`${clientData?._phone?.country_code} ${clientData?._phone?.value}`}
                                            onChange={(value, formattedValue, nameValue) => {
                                                handlePhoneInput(value, formattedValue, nameValue);
                                            }}
                                            onKeyUp={(value, formattedValue, nameValue) => {
                                                handlePhoneInput(value, formattedValue, nameValue);
                                            }}
                                            onlyCountries={['ae', 'bh', 'jo', 'kw', 'om', 'qa', 'sa', 'ye']}
                                            preserveOrder={['onlyCountries', 'preferredCountries']}
                                            inputProps={{
                                                name: "_phone",
                                                autoFocus: false,
                                                placeholder: "Phone Number *",
                                            }}
                                            enableSearch
                                            name="mobileNo"
                                            searchPlaceholder="Search Country"
                                            disableSearchIcon
                                        />
                                        <FormError show={!clientData?._phone?.isValid && showCPError}
                                            error={
                                                clientData?._phone?.value?.length < 9 && clientData?._phone?.value?.length > 0
                                                    ? 'Please enter correct phone number.'
                                                    : 'Please enter phone number.'
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Business license no* </label>
                                    <input type="text"
                                        name='business_licence_no'
                                        value={clientData?.business_licence_no?.value}
                                        onChange={handleInput}
                                        // onKeyUp={handleInput}
                                        // onKeyDown={(e) => handleRegexValidation(e)}
                                        maxLength={15}
                                    />
                                    <FormError
                                        show={!clientData?.business_licence_no?.isValid && showCPError || regexError === 'business_licence_no'}
                                        error={regexError === 'business_licence_no' ? 'Please enter only numeric character without space.' : 'Please enter business license no'} />
                                    {/* {regexError === 'business_licence_no' && <FormError show={true} error={'Please enter only alphabetical character without space.'} />} */}
                                </div>
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Emirates id* </label>
                                    <input type="text"
                                        name='Emirates_ID'
                                        value={clientData?.Emirates_ID?.value}
                                        onChange={handleInput}
                                        // onKeyUp={handleInput}
                                        // onKeyDown={(e) => handleRegexValidation(e)}
                                        maxLength={18}
                                    // onPaste={handleRegexValidation}
                                    />
                                    <FormError show={!clientData?.Emirates_ID?.isValid && showCPError || regexError === 'Emirates_ID'}
                                        error={regexError === 'Emirates_ID' ? 'Please enter only numeric character without space.' : 'Please enter emirates id.'} />
                                    {/* {regexError === 'Emirates_ID' && <FormError show={true} error={'Please enter only alphabetical character without space.'} />} */}
                                </div>
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Nationality* </label>
                                    <input type="text"
                                        name='Nationality'
                                        value={clientData?.Nationality?.value}
                                        onChange={handleInput}
                                    // onKeyUp={handleInput}
                                    // onKeyDown={(e) => handleRegexValidation(e)}
                                    // onChangeCapture={(e) => handleRegexValidation(e)}
                                    // onPaste={handleRegexValidation}  // Add this line to handle pasting events
                                    />
                                    <FormError show={!clientData?.Nationality?.isValid && showCPError || regexError === 'Nationality'}
                                        error={regexError === 'Nationality' ? 'Please enter only alphabetical character.' : 'Please enter nationality.'} />
                                    {/* {regexError === 'Nationality' && <FormError show={true} error={'Please enter only alphabetical character without space.'} />} */}
                                </div>
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Text id* </label>
                                    <input type="text"
                                        name='text_id'
                                        value={clientData?.text_id?.value}
                                        onChange={handleInput}
                                        // onKeyUp={handleInput}
                                        // onKeyDown={(e) => handleRegexValidation(e)}
                                        maxLength={12}
                                    />
                                    <FormError show={!clientData?.text_id?.isValid && showCPError} error={'Please enter text id.'} />
                                    {/* {regexError === 'text_id' && <FormError show={true} error={'Please enter only alphabetical character without space.'} />} */}
                                </div>

                            </div>
                            {/* <div className="form-control mt-20">
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Native Address</label>
                                    <input type="text"
                                        name='native_address'
                                        value={clientData?.native_address?.value}
                                        onChange={handleInput}
                                        onKeyUp={handleInput}
                                    />
                                </div>

                            </div> */}
                            <div className="form-control mt-20">
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Address *</label>
                                    <input type="text"
                                        name='address'
                                        value={clientData?.address?.value ? clientData?.address?.value : ''}
                                        onChange={handleInput}
                                        onKeyUp={handleInput}
                                    />
                                    <FormError show={!clientData?.address?.isValid && showCPError} error={'Please enter address.'} />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Country*</label>
                                    <CountrySelector Defaultvalue={singleClient?.data?.country} />
                                    <FormError show={!clientData?.country?.isValid && showCPError} error={'Please enter country.'} />
                                </div>
                                <div className="form-field  ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > State*</label>
                                    <StateSelector Defaultvalue={singleClient?.data?.state} />
                                    <FormError show={!clientData?.state?.isValid && showCPError} error={'Please enter state.'} />
                                </div>
                                <div className="form-field  ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > City*</label>
                                    <CitySelector Defaultvalue={singleClient?.data?.city} />
                                    <FormError show={!clientData?.city?.isValid && showCPError} error={'Please enter city.'} />
                                </div>
                                <div className="form-field ">
                                    <label htmlFor="" className='mb-3 d-block text-xxs' > Zip code *</label>
                                    <input type="text"
                                        name='postal_code'
                                        value={clientData?.postal_code?.value}
                                        onChange={handleInput}
                                        // onKeyUp={handleInput}
                                        // onKeyDown={(e) => handleRegexValidation(e)}
                                        maxLength={6}
                                    />

                                    <FormError show={regexError === "postal_code" || !clientData?.postal_code?.isValid && showCPError}
                                        error={regexError === "postal_code" ? "Please enter only numeric character without space." : !clientData?.postal_code?.isValid && showCPError ? 'It should not be less than 5 & more than 6 digits.' : ''}
                                    />

                                </div>
                            </div>
                            <div className="save-btn-wrap d-flex mt-40 ">
                                <CommanButton Text={'Save'} ClassName={'save-btn'} onClick={() => handlePersonalClientSaveBtn()} />
                                <CommanButton Text={'Cancel'} cancel={true} type={'button'} onClick={() => setActiveEdit(e => !e)} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PersonalClientData