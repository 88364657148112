import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { notificationTrigger, setNotifications, setSeenNoty } from '../../store/Actions/Notifications'
import Auth from '../../Classes/Auth'
import { useNavigate } from 'react-router-dom'
import { setActiveTab } from '../../store/Actions/PersonalDetails'
import useDocumentDetails from '../EmployeePersonalDetails/useDocumentDetails'

const useNotifications = () => {
    const userId = Auth?.user()?.user_id
    const notifications = useSelector((state) => state.notifications)
    const { DocuemntEditBtnHandler } = useDocumentDetails()
    const dispatch = useDispatch()
    const naviagte = useNavigate()

    function onMsgClickHandler(id, is_seen, employee_id, client_id, docType) {
        if (!is_seen) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('is_seen', 1);
            dispatch(setSeenNoty(userId, formData))
        }
        naviagte(`/employees-management/id=${employee_id}?client_id=${client_id}`)
        dispatch(setActiveTab("Documents"))
        DocuemntEditBtnHandler(employee_id, docType)
    }

    useEffect(() => {
        dispatch(notificationTrigger(userId))
        dispatch(setNotifications(userId))
    }, [])
    return { notifications, onMsgClickHandler }
}

export default useNotifications