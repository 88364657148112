import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Dashboard from '../DashBoard'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import SearchControl from '../../CommanComponents/SearchControl/SearchControl'
import CommanButton from '../../CommanComponents/CommanButton/CommanButton'
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick'
import Loader from '../../CommanComponents/Loader/Loader'
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
import useClients from '../../CustomHooks/Clients/useClients'
import { clientSearch, resetClientSearch, resetDeleteClient, setClientType, setDeleteClient } from '../../store/Actions/Clients'
import './clients.scss'
import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import Auth from '../../Classes/Auth'
import PaginationSimple from '../../CommanComponents/PaginationSimple/PaginationSimple'
import usePageListData from '../../CustomHooks/usePageListData'
import { setClient } from '../../store/Actions/EmployeeManagement'
const Clients = () => {
    const { } = useClients()
    const clients = useSelector((e) => e.clients)
    const ref = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = Auth?.user()?.user_id // this is for added by user
    const [searchVal, setSearchVal] = useState(clients?.fetch_type?.length == 0 ? '' : clients?.fetch_type)
    const [activeManage, setActiveManage] = useDetectOutsideClick(ref, false)
    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const [pageNumber, setPageNumber] = useState(1)

    const debouncedSearch = useRef(null);

    useEffect(() => {
        debouncedSearch.current = debounce((value) => {
            dispatch(clientSearch(userId, value));
        }, 400);
    }, [dispatch]);

    const handleSearch = useCallback((e) => {
        const { value } = e.target;
        setSearchVal(e.target.value);
        debouncedSearch.current(e.target.value); // Call the debounced function
    }, []);

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    const handleResetSearch = () => {
        setSearchVal('')
        dispatch(resetClientSearch(userId))
    }



    const handleManageBtn = (e, val) => {
        e.stopPropagation()
        setActiveManage(activeManage === val ? -1 : val)
    }

    const EmployeeClickHandle = (val) => {
        navigate(`id=${val}`)
        dispatch(setClientType("new"))
    }


    // Handle delete start

    const deleteHandler = (id) => {
        dispatch(setDeleteClient(id, userId))
    }

    const deleteClickHandler = (id) => {
        setDeleteId(id);
        setDeletePop(deletePop === id ? -1 : id);
    }

    const CancleDelete = () => {
        setDeleteId("");
        setDeletePop(false);
    }

    const handleDeleteBtn = () => {
        setDeleteId("");
        setDeletePop(false);
        deleteHandler(deleteId)
    }

    useEffect(() => {
        if (clients?.delete) {
            dispatch(resetDeleteClient())
        }
    }, [clients?.delete])

    // Pagination code 

    const dataForCurrentPage = usePageListData(clients?.data, pageNumber)

    const goToEmployeeHandle = (val) => {
        dispatch(setClient(val))
        navigate('/employees-management')
    }


    return (
        < >
            <div className="employees-management-wrapper clients-main-wrapper">
                <div className="employees-management-wrapper">
                    <div className="heading-search-item justify-between align-center">
                        <SectionHeading>
                            Clients List
                        </SectionHeading>
                        <div className='d-flex align-center justify-center search-page-wrap '>
                            <SearchControl
                                value={searchVal}
                                placeholder={'Search...'}
                                onChange={handleSearch}
                                reset={() => handleResetSearch()}
                                classNameWrappper={'employees-search'}
                            />
                            {/* ------- Pagination add ------ */}
                        </div>

                        <div className="actions-wrappper">
                            <CommanButton onClick={() => { return navigate('add-new-client'), dispatch(setClientType("new")) }} ClassName={"action-btn"} >
                                <p className='w-700'><span className='text-xs'>  + </span>Client</p>
                            </CommanButton>
                        </div>
                        {/* <div className="pagination-wrapper d-flex align-center justify-right">
                            <div className="pagination-item d-flex align-center">
                                <p className="text-xxs w-400 secondary mr-10 text-nowrap">
                                    {`1-${clients?.data?.length} of ${clients?.data?.length}`}
                                </p>

                                <div className="arrow-btn-wrap d-flex align-center">
                                    <button
                                        className={`arrow left transparent mr-10`}
                                    >
                                        <i className='icon'></i>
                                    </button>
                                    <button
                                        className={`arrow right transparent`}
                                    >   <i ></i></button>
                                </div>
                            </div>
                        </div> */}
                        {/* {clients?.data?.length ? */}
                        <PaginationSimple
                            className={"document-pagenation"}
                            data={clients?.data}
                            shows={clients?.data?.length < 20 ? clients?.data?.length : "20"}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                        />
                        {/* : '' */}
                        {/* } */}
                    </div>
                    <div className="employees-management-table-wrapper mt-10">
                        <div className="table-wrap">
                            {dataForCurrentPage?.length ?
                                <table className="table-item">
                                    <thead className='table-head'>
                                        <tr className="table-row">
                                            <th className='srNo'>ID  </th>
                                            <th className='name'>Business Name  </th>
                                            <th className='email'>Email Address</th>
                                            <th className='phone'>Phone Number</th>
                                            <th className='country'>Country</th>
                                            <th className='action'>Action</th>
                                        </tr>
                                    </thead>


                                    <tbody className='table-body'>
                                        {dataForCurrentPage?.map((val, key) => {
                                            return (
                                                <tr className="table-row" key={key} onClick={() => { goToEmployeeHandle(val) }}>
                                                    <td> {val.id} </td>
                                                    <td> {val.business_name ? val.business_name : val?.first_name + " " + val?.last_name}  </td>
                                                    <td> {val.email} </td>
                                                    <td> {val.phone_no} </td>
                                                    <td> {val.country} </td>
                                                    <td onClick={(e) => e.stopPropagation()}>
                                                        <div className='dots-wrap d-flex justify-center align-center relative' onClick={(e) => { handleManageBtn(e, key) }} >
                                                            <i className='dots-icon' > </i>
                                                        </div>
                                                        {<div className={`dropdown-wrap absolute ${activeManage === key && 'active'}`} ref={ref}>
                                                            {/* <div className="item d-flex align-center mb-10 pointer "  >
                                                                <i className='icons mr-5 add '></i>
                                                                <p className="text-2xs w-500 secondary">Add Document</p>
                                                            </div> */}
                                                            {(Auth?.boolUserType() == 3 || Auth?.boolUserType() == 4) && <div className="item d-flex align-center mb-10 pointer"  >
                                                                <i className='icons mr-5 delete'></i>
                                                                <p className="text-2xs w-500 secondary"
                                                                    onClick={() => { deleteClickHandler(val.id) }}
                                                                >Delete</p>
                                                            </div>}
                                                            <div className="item d-flex align-center pointer" onClick={() => EmployeeClickHandle(val.id)}>
                                                                <i className='icons mr-5 edit'></i>
                                                                <p className="text-2xs w-500 secondary" >Edit</p>
                                                            </div>
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                :
                                <DataNotFound />
                            }
                            {/* {!clients?.data?.length && clients?.loading && <Loader />} */}
                            {/* {!clients?.data?.length && !clients?.loading && <DataNotFound />} */}
                        </div>
                    </div>
                </div>
            </div>
            {deletePop && <DeleteConfirmPop
                handleCancelVar={CancleDelete}
                deleteVarHandler={() => handleDeleteBtn()}
            />}
        </>
    )
}

export default Clients