import { combineReducers } from "redux";
import auth from "./auth";
import successMessagePopup from "./successMessagePopup";
import topActionButtons from "./topActionButtons";
import countryStateCity from "./countryStateCity";
import stepperBar from "./stepperBar";
import forgotPassword from "./forgotPassword";
import personalDetails from "./personalDetails";
import employeeManagement from "./employeeManagement";
import documentUpload from "./documentUpload";
import folders from "./folders";
import reminders from "./reminders";
import notifications from "./notifications";
import documents from "./documents";
import clients from "./clients";
import accessManagement from "./accessManagement";
import support from "./support";
import user from "./user";
import allEmployees from "./allEmployees";
import settings from "./settings";
import openForceChangePass from "./openForceChangePass";
import loaderFetching from "./loaderFetching";
import adminRegisteredUser from "./adminRegisteredUser";
import commonerror from "./commonerror";
const RootReducer = combineReducers({
    auth,
    successMessagePopup,
    topActionButtons,
    countryStateCity,
    stepperBar,
    forgotPassword,
    personalDetails,
    employeeManagement,
    documentUpload,
    folders,
    reminders,
    notifications,
    documents,
    clients,
    accessManagement,
    support,
    user,
    allEmployees,
    settings,
    openForceChangePass,
    loaderFetching,
    adminRegisteredUser,
    commonerror,
})

export default RootReducer;