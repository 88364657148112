import React, { useEffect, useMemo, useRef } from 'react'
import ImageViewer from '../../../CommanComponents/ImageViewer/ImageViewer'
import useDetectOutsideClick from '../../../CustomHooks/useDetectOutsideClick'
import { useDispatch, useSelector } from 'react-redux'
import DocPreview from './DocPreview/DocPreview'
// import { DummyDocImage } from '../../../config'
import DummyDocImage from '../../../Assets/icons/dummy-doc-img.svg'
import Auth from '../../../Classes/Auth'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllPassportDocs } from '../../../store/Actions/Documents'
import ValidationFile from '../../../Classes/ValidationFile'
import { setActiveTab } from '../../../store/Actions/PersonalDetails'
import useDocumentDetails from '../../../CustomHooks/EmployeePersonalDetails/useDocumentDetails'
import { setDocType } from '../../../store/Actions/DocumentUpload'
import DataNotFound from '../../../CommanComponents/DataNotFound/DataNotFound'
import { dateFormat } from '../../../CustomHooks/dateFormat'
import { ALLOW_IMAGE_EXTENSIONS, getBaseUrl } from '../../../config';
import getFileExtension from '../../../HelperFunction/getFileExtension';
import FileIcon from '../../../Assets/images/fileIcon.png'
import dummyImg from '../../../Assets/icons/dummy-doc-img.svg'
import { fileDownloaderHelperFunc } from '../../../HelperFunction/fileDownloaderHelperFunc'
const DocumentView = ({ gridView, document_param, DocuemntEditBtnHandler, searchVal, pageNumber }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const ref = useRef(null)
    const prevRef = useRef(null)
    const documents = useSelector(e => e.documents)
    const [activeOptionsDrop, setActiveOptionsDrop] = useDetectOutsideClick(ref, false)
    const [imageToShow, setImageToShow] = useDetectOutsideClick(ref, false)

    const handleDotsBtn = (e, val) => {
        e.stopPropagation()
        setActiveOptionsDrop(val)
    }

    const previewOpen = (data) => {
        prevRef.current.open();
        setImageToShow(data)
        console.log(data, "data123")
    }

    const previewClose = () => {
        prevRef.current.close();
    }

    const editHandler = (id, docType) => {
        navigate(`/employees-management/id=${id}`)
        dispatch(setActiveTab("Documents"))
        DocuemntEditBtnHandler(id, docType)
    }

    // Filter documents with at least one non-null document
    // const filteredDocuments = documents?.data.filter(
    //     val => val.front_document || val.back_document
    // );
    const filteredDocuments = documents?.data
    console.log(documents?.data, filteredDocuments, "filteredDocuments")
    // Pagination code 
    // Calculate the start and end indices for the current page
    // const startIndex = (pageNumber - 1) * showsPerPage;
    // const endIndex = Math.min(pageNumber * showsPerPage, documents?.data.length);

    // // Slice the documents array to get the data for the current page
    // const dataForCurrentPage = useMemo(() => {
    //     return documents?.data.slice(startIndex, endIndex);
    // }, [pageNumber, documents, startIndex, endIndex]);

    // Pagination code
    const showsPerPage = filteredDocuments?.length < 20 ? filteredDocuments?.length : 20; // Number of items to show per page

    const startIndex = (pageNumber - 1) * showsPerPage;
    const endIndex = Math.min(pageNumber * showsPerPage, filteredDocuments?.length);

    const dataForCurrentPage = useMemo(() => {
        return filteredDocuments?.slice(startIndex, endIndex);
    }, [pageNumber, filteredDocuments, startIndex, endIndex]);


    return (
        <>
            {gridView === "grid" ? filteredDocuments?.length ? filteredDocuments.map((val, key) => {
                const frontDocument = val?.front_document;
                const backDocument = val?.back_document;

                const frontExtension = getFileExtension(frontDocument ?? '');
                const backExtension = getFileExtension(backDocument ?? '');

                const isFrontAllowed = frontDocument && ALLOW_IMAGE_EXTENSIONS.includes(frontExtension);
                const isBackAllowed = backDocument && ALLOW_IMAGE_EXTENSIONS.includes(backExtension);
                return (
                    <>
                        {/* {val?.back_document || val?.front_document ? */}
                        <div className="document-item-wrap file-wrapper relative" key={key}  >
                            <>
                                <div className="file-top-wrap d-flex mb-10 align-center justify-between">
                                    {/* <i className="file-icon"></i> */}
                                    <p className="text-xxs w-400 secondary mr-15 folder-name" >{val?.employee?.first_name + ' ' + val?.employee?.last_name} </p>
                                    <div className="dots-wrap d-flex justify-center align-center" onClick={(e) => handleDotsBtn(e, key)}  >
                                        <i className='dots-icon'> </i>
                                    </div>
                                </div>
                                <div className="file-img-wrap"
                                    // onDoubleClick={() => previewOpen(val)}
                                    // onDoubleClick={() => { (isFrontAllowed || isBackAllowed) ? previewOpen(val) : (!isFrontAllowed && !isBackAllowed) && fileDownloaderHelperFunc(getBaseUrl() + val?.back_document) && fileDownloaderHelperFunc(getBaseUrl() + val?.back_document) }}
                                    onDoubleClick={() => {
                                        if (isFrontAllowed && isBackAllowed) {
                                            previewOpen(val);
                                        } else if (isFrontAllowed && val?.back_document == null) {
                                            previewOpen(val);
                                        } else if (isBackAllowed && val?.front_document == null) {
                                            previewOpen(val);
                                        } else if (isFrontAllowed && !isBackAllowed) {
                                            previewOpen(val)
                                            fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                        } else if (isBackAllowed && !isFrontAllowed) {
                                            previewOpen(val)
                                            fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                        }

                                        else {
                                            // Assuming getBaseUrl() returns the base URL
                                            val?.back_document != null && fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                            val?.front_document != null && fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                            // setTimeout(() => {
                                            // }, 1000);  
                                        }
                                    }}
                                // onDoubleClick={() => {
                                //     if (false) {
                                //         previewOpen(val);
                                //     } else if (false) {
                                //         previewOpen(val);
                                //     } else {
                                //         fileDownloaderHelperFunc(getBaseUrl() + val?.back_document) &&
                                //             fileDownloaderHelperFunc(getBaseUrl() + val?.front_document)
                                //     }
                                // }}
                                >
                                    <ImageViewer
                                        object={isFrontAllowed ? val?.front_document : isBackAllowed ? val.back_document : ""}
                                        defaultImage={val?.front_document !== null && val.back_document !== null ? FileIcon : dummyImg}
                                        className={!isFrontAllowed && !isBackAllowed && 'doc-img'}

                                    />
                                </div>
                                {console.log(val?.front_document, val.back_document, "val?.front_document !== null && val.back_document !== null")}
                                {activeOptionsDrop === key &&
                                    <div className={`dropdown-wrap absolute ${true && "active"}`} ref={ref} >
                                        {(val?.front_document != null || val.back_document !== null) && <p className="text-2xs w-500 secondary pointer mb-10"
                                            // onClick={() => previewOpen(val)}
                                            onClick={() => {
                                                if (isFrontAllowed && isBackAllowed) {
                                                    previewOpen(val);
                                                } else if (isFrontAllowed && val?.back_document == null) {
                                                    previewOpen(val);
                                                } else if (isBackAllowed && val?.front_document == null) {
                                                    previewOpen(val);
                                                } else if (isFrontAllowed && !isBackAllowed) {
                                                    previewOpen(val)
                                                    fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                                } else if (isBackAllowed && !isFrontAllowed) {
                                                    previewOpen(val)
                                                    fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                                }

                                                else {
                                                    // Assuming getBaseUrl() returns the base URL
                                                    val?.back_document != null && fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                                    val?.front_document != null && fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                                    // setTimeout(() => {
                                                    // }, 1000);  
                                                }
                                            }}

                                        >Preview</p>}
                                        <div className="item d-flex align-center pointer " onClick={() => editHandler(val?.employee_id, val?.Document_type)} >
                                            <p className="text-2xs w-500 secondary" >Edit</p>
                                        </div>
                                        {/* <p className="text-2xs w-500 secondary pointer">Access</p> */}
                                    </div>}
                            </>
                        </div >
                        {/* : ""} */}

                    </>
                )
            })
                :
                <DataNotFound />
                :
                gridView === "list" && document_param !== "all" ?
                    dataForCurrentPage?.length ?
                        <div className="list-folder-item-wrap ">
                            <div className="folder-table-wrapper ">
                                <div className="table-wrap">
                                    <table className="table-item">
                                        <thead className='table-head'>
                                            <tr className="table-row">
                                                <th className='name'>Employee Name  </th>
                                                <th className='email'>Document Type</th>
                                                <th className='phone'>Expiry Date</th>
                                                <th className='country'>Country</th>
                                                <th className='action'>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody className='table-body'>
                                            {dataForCurrentPage?.map((val, key) => {
                                                const frontDocument = val?.front_document;
                                                const backDocument = val?.back_document;

                                                const frontExtension = getFileExtension(frontDocument ?? '');
                                                const backExtension = getFileExtension(backDocument ?? '');

                                                const isFrontAllowed = frontDocument && ALLOW_IMAGE_EXTENSIONS.includes(frontExtension);
                                                const isBackAllowed = backDocument && ALLOW_IMAGE_EXTENSIONS.includes(backExtension);
                                                return (
                                                    // val?.back_document || val?.front_document ?
                                                    <tr className="table-row" key={key}
                                                        // onDoubleClick={() => previewOpen(val)}
                                                        onDoubleClick={() => {
                                                            if (isFrontAllowed && isBackAllowed) {
                                                                previewOpen(val);
                                                            } else if (isFrontAllowed && val?.back_document == null) {
                                                                previewOpen(val);
                                                            } else if (isBackAllowed && val?.front_document == null) {
                                                                previewOpen(val);
                                                            } else if (isFrontAllowed && !isBackAllowed) {
                                                                previewOpen(val)
                                                                fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                                            } else if (isBackAllowed && !isFrontAllowed) {
                                                                previewOpen(val)
                                                                fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                                            }

                                                            else {
                                                                // Assuming getBaseUrl() returns the base URL
                                                                val?.back_document != null && fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                                                val?.front_document != null && fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                                                // setTimeout(() => {
                                                                // }, 1000);  
                                                            }
                                                        }}
                                                    >
                                                        <td  > {val?.employee?.first_name + ' ' + val?.employee?.last_name} </td>
                                                        <td> {val?.Document_type} </td>
                                                        <td > {dateFormat(val?.Date_of_Expiry)} </td>
                                                        <td> {val?.Nationality} </td>
                                                        <td onClick={(e) => e.stopPropagation()} className='relative'>
                                                            <div className='dots-wrap d-flex justify-center align-center ' onClick={(e) => { handleDotsBtn(e, key) }} >
                                                                <i className='dots-icon' > </i>
                                                            </div>
                                                            {
                                                                activeOptionsDrop === key && <div className={`dropdown-wrap absolute ${true && 'active'}`} ref={ref}>
                                                                    {(val?.front_document != null || val.back_document !== null) && <div className="item d-flex align-center pointer mb-10"
                                                                        //  onClick={() => previewOpen(val)} 
                                                                        onClick={() => {
                                                                            if (isFrontAllowed && isBackAllowed) {
                                                                                previewOpen(val);
                                                                            } else if (isFrontAllowed && val?.back_document == null) {
                                                                                previewOpen(val);
                                                                            } else if (isBackAllowed && val?.front_document == null) {
                                                                                previewOpen(val);
                                                                            } else if (isFrontAllowed && !isBackAllowed) {
                                                                                previewOpen(val)
                                                                                fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                                                            } else if (isBackAllowed && !isFrontAllowed) {
                                                                                previewOpen(val)
                                                                                fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                                                            }

                                                                            else {
                                                                                // Assuming getBaseUrl() returns the base URL
                                                                                val?.back_document != null && fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                                                                val?.front_document != null && fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                                                                // setTimeout(() => {
                                                                                // }, 1000);  
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className='icons mr-5 prev'></i>
                                                                        <p className="text-2xs w-500 secondary" >Preview</p>
                                                                    </div>}
                                                                    <div className="item d-flex align-center pointer " onClick={() => editHandler(val?.employee_id, val?.Document_type)} >
                                                                        <i className='icons mr-5 edit'></i>
                                                                        <p className="text-2xs w-500 secondary" >Edit</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                    // : ''
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : <DataNotFound /> :



                    gridView === "list" && document_param == "all" && searchVal !== "" ?
                        dataForCurrentPage?.length ?
                            <div className="list-folder-item-wrap ">
                                <div className="folder-table-wrapper ">
                                    <div className="table-wrap">
                                        <table className="table-item">
                                            <thead className='table-head'>
                                                <tr className="table-row">
                                                    <th className='name'>Employee Name  </th>
                                                    <th className='email'>Document Type</th>
                                                    <th className='phone'>Expiry Date</th>
                                                    <th className='country'>Country</th>
                                                    <th className='action'>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody className='table-body'>
                                                {dataForCurrentPage?.map((val, key) => {
                                                    const frontDocument = val?.front_document;
                                                    const backDocument = val?.back_document;

                                                    const frontExtension = getFileExtension(frontDocument ?? '');
                                                    const backExtension = getFileExtension(backDocument ?? '');

                                                    const isFrontAllowed = frontDocument && ALLOW_IMAGE_EXTENSIONS.includes(frontExtension);
                                                    const isBackAllowed = backDocument && ALLOW_IMAGE_EXTENSIONS.includes(backExtension);
                                                    return (
                                                        // val?.back_document || val?.front_document ?
                                                        <tr className="table-row" key={key}
                                                            //  onDoubleClick={() => previewOpen(val)}
                                                            onDoubleClick={() => {
                                                                if (isFrontAllowed && isBackAllowed) {
                                                                    previewOpen(val);
                                                                } else if (isFrontAllowed && val?.back_document == null) {
                                                                    previewOpen(val);
                                                                } else if (isBackAllowed && val?.front_document == null) {
                                                                    previewOpen(val);
                                                                } else if (isFrontAllowed && !isBackAllowed) {
                                                                    previewOpen(val)
                                                                    fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                                                } else if (isBackAllowed && !isFrontAllowed) {
                                                                    previewOpen(val)
                                                                    fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                                                }

                                                                else {
                                                                    // Assuming getBaseUrl() returns the base URL
                                                                    val?.back_document != null && fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                                                    val?.front_document != null && fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                                                    // setTimeout(() => {
                                                                    // }, 1000);  
                                                                }
                                                            }}
                                                        >
                                                            <td  > {val?.employee?.first_name + ' ' + val?.employee?.last_name} </td>
                                                            <td> {val?.Document_type} </td>
                                                            <td > {dateFormat(val?.Date_of_Expiry)} </td>
                                                            <td> {val?.Nationality} </td>
                                                            <td onClick={(e) => e.stopPropagation()} className='relative'>
                                                                <div className='dots-wrap d-flex justify-center align-center ' onClick={(e) => { handleDotsBtn(e, key) }} >
                                                                    <i className='dots-icon' > </i>
                                                                </div>
                                                                {
                                                                    activeOptionsDrop === key && <div className={`dropdown-wrap absolute ${true && 'active'}`} ref={ref}>
                                                                        <div className="item d-flex align-center pointer"
                                                                            // onClick={() => previewOpen(val)} 
                                                                            onClick={() => {
                                                                                if (isFrontAllowed && isBackAllowed) {
                                                                                    previewOpen(val);
                                                                                } else if (isFrontAllowed && val?.back_document == null) {
                                                                                    previewOpen(val);
                                                                                } else if (isBackAllowed && val?.front_document == null) {
                                                                                    previewOpen(val);
                                                                                } else {
                                                                                    // Assuming getBaseUrl() returns the base URL
                                                                                    fileDownloaderHelperFunc(getBaseUrl() + val?.back_document,);
                                                                                    fileDownloaderHelperFunc(getBaseUrl() + val?.front_document,);
                                                                                    // setTimeout(() => {
                                                                                    // }, 1000);  
                                                                                }
                                                                            }}
                                                                        >
                                                                            <i className='icons mr-5 prev'></i>
                                                                            <p className="text-2xs w-500 secondary" >Preview</p>
                                                                        </div>
                                                                        <div className="item d-flex align-center pointer mt-10" onClick={() => editHandler(val?.employee_id, val?.Document_type)} >
                                                                            <i className='icons mr-5 edit'></i>
                                                                            <p className="text-2xs w-500 secondary" >Edit</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        // : ''
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : <DataNotFound /> : ""

            }

            <DocPreview reffrence={prevRef}
                onClose={previewClose} data={imageToShow}
                setImageToShow={setImageToShow}
            />
        </>
    )
}

export default DocumentView