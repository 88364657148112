import React from 'react';
import { AccessConsumer } from '../../../../../../../Context/AccessContext';
import FormError from '../../../../../../../CommanComponents/Form/FormError';
import Dropdown from '../../../../../../../CommanComponents/Dropdown/Dropdown';
import CommanButton from '../../../../../../../CommanComponents/CommanButton/CommanButton';
import DataNotFound from '../../../../../../../CommanComponents/DataNotFound/DataNotFound';
import { Link } from 'react-router-dom';

const AccessStep1 = ({
    accessManagement,
    giveAccessBtn,
    accessBOEmail,
    handleInputBOchange,
    acessBOError,
    nextStep
}) => {
    return (
        <AccessConsumer>
            {(value) => {
                return (
                    <>
                        {/* <div>
                            <h2>Current Access</h2>
                            <label>Email: <input type="email" name="email" value={value?.formData.email} onChange={value?.handleInputChange} placeholder="Enter email" /></label>
                            <label>Permission:
                                <select name="permission" value={value?.formData.permission} onChange={value?.handleInputChange}>
                                    <option value="editor">Editor</option>
                                    <option value="viewer">Viewer</option>
                                </select>
                            </label>
                            <div className="navigation">
                                <button className="back-btn" onClick={value?.previousStep}>Back</button>
                                <button onClick={value?.handleSubmit}>Submit</button>
                            </div>
                        </div> */}
                        {!accessManagement?.data?.access_list?.length > 0 ? <form className="access-modal-content-wrap"  >
                            <div className="form-wrapper">
                                <div className="form-field relative">
                                    <input
                                        type="text"
                                        className='access-input base'
                                        placeholder='Enter Email *'
                                        value={accessBOEmail?.email?.value}
                                        name={"email"}
                                        onChange={(e) => handleInputBOchange(e)}

                                    />
                                    <FormError show={!accessBOEmail?.email?.isValid && acessBOError
                                        || accessManagement?.error
                                    }
                                        error={!accessBOEmail?.email?.isValid ? "Enter valid email." : <>{`${accessManagement?.message}`} {accessManagement?.message == "Typing center with this email not found!" && <span className='send-invite-access pointer w-600 primary'> Send invitation.</span>}</>} />

                                    <div className="access-selector absolute">
                                        <Dropdown
                                            defaultValue="Editor"
                                            data={['Editor', 'Can view']}
                                            disableList={['Can view']}
                                            border={'1px solid #2C4364'}
                                            ClassName="select-aceess-btn"
                                            width={'100%'}
                                        />
                                    </div>
                                </div>

                            </div>
                            <CommanButton
                                Text={accessManagement?.loading ? 'Sending...' : 'Next'}
                                ClassName={'access-btn mt-50  '}
                                type={"button"}
                                onClick={(e) => { giveAccessBtn(e, nextStep) }}
                            />
                        </form>
                            : <DataNotFound
                                text={"Already given access. First please remove access and then add access."}
                                className={"base"}
                                textCenter={true}
                            />
                        }
                    </>
                );
            }}
        </AccessConsumer>
    );
};

export default AccessStep1;
